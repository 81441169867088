import React from 'react'
import { Link } from 'gatsby';
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="ccl-404">
      <h1>We're sorry...</h1>
      <p>We couldn't find the page you requested.</p>
      <p>Want to try again?</p>
      <p>
        <Link to="/">START HERE</Link>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage
